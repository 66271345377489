import { create } from '@storybook/theming/create';
import { Colors } from '../src/styles';

export default create({
  base: 'light',
  colorPrimary: 'hotpink',
  colorSecondary: Colors.primary,

  brandTitle: 'Yunhao Shen',
  brandUrl: 'https://yunhaoshen.com',
});
