export const white = "#ffffff"
export const black = "#000000"

export const blue60 = "#005b7b"
export const blue50 = "#0079a4"
export const blue40 = "#00aedb"
export const blue30 = "#77dbf4"
export const blue20 = "#beeefa"
export const blue10 = "#e5faff"

export const grey90 = "#162934"
export const grey80 = "#354a53"
export const grey70 = "#546e78"
export const grey60 = "#81959e"
export const grey50 = "#baccd4"
export const grey40 = "#d5e5ed"
export const grey30 = "#ebeff1"
export const grey20 = "#f5f7f8"
export const grey10 = "#fafbfc"

export const red50 = "#ca2b43"
export const red40 = "#e85168"
export const red30 = "#fe7489"
export const red20 = "#ffbfc9"
export const red10 = "#ffebee"

export const green60 = "#005748"
export const green50 = "#028069"
export const green40 = "#05aa8d"
export const green30 = "#89c9ba"
export const green20 = "#bde2da"
export const green10 = "#e6f4f1"

export const orange60 = "#a25710"
export const orange50 = "#c7460a"
export const orange40 = "#f88419"
export const orange30 = "#fbaf6a"
export const orange20 = "#fed9b7"
export const orange10 = "#ffeedf"

export const yellow60 = "#846806"
export const yellow50 = "#d6a807"
export const yellow40 = "#f8ca27"
export const yellow30 = "#f9dc78"
export const yellow20 = "#f9e7a8"
export const yellow10 = "#fdf7e1"

export const purple50 = "#9d489c"
export const purple40 = "#bf74bc"
export const purple30 = "#e09fdd"
export const purple20 = "#e5bde3"
export const purple10 = "#f8e8f7"

export const olive50 = "#777132"
export const olive40 = "#a69e45"
export const olive30 = "#c9c171"
export const olive20 = "#eee8a9"
export const olive10 = "#f9f6de"

// Theme variables
export const screenBackground = grey20
export const modalBackground = "#00000088"

export const primary = "#034EA2"
export const success = green50
export const danger = red50
export const warning = yellow50
export const info = blue50

export const textPrimary = grey90
export const textSecondary = grey70
